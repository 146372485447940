import moment from 'moment';
import { createCrudActions } from '../utils/crudGenerator';
import { axios } from "../connection/ConnectionHandler";
import { keyBy } from "lodash";
import { SET_BUDGET_LIST, SHOW_NOTIFICATION } from "../constants";

const formatBudgets = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        date: item.date ? moment(item.date) : null
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchBudgets,
    create: createBudget,
    update: updateBudget,
    delete: deleteBudgets
} = createCrudActions('BUDGET', "Presupuesto", formatBudgets);

export const fetchBudgetById = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`/budget/${id}`);
        const formattedBudget = formatBudgets(response.data);
        const budgetArray = [formattedBudget];
        const budgetObject = keyBy(budgetArray, '_id');

        dispatch({
            type: SET_BUDGET_LIST,
            payload: budgetObject
        });

        return response.status;
    } catch (err) {
        console.error(err);
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                show: true,
                status: "error",
                message: "Error al obtener el presupuesto.",
            },
        });
        return err;
    }
};